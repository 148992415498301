import React from "react";
import { graphql } from "gatsby"
import Link from 'gatsby-link';
import _ from "underscore"
import { Container, Carousel,  Row, Col } from "react-bootstrap";
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import ReactHtmlParser from 'react-html-parser';

import SEO from "../../utils/seo";
import Layout from "../../layout/layout";
import IntroBlock from "../../components/IntroBlock";
import ContentContainer from "../../components/ContentContainer";

class IndexPage extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {       
      page: this.props.data.page.childMarkdownRemark,
      folioItems: this.props.data.folio.nodes,
      blogPosts: this.props.data.blog.nodes,
      isLoading: false,
      folioSlide: {
        isOpen: false,        
        title: '',
        subtitle: '',
        description: '',
        imageURL: '',
        carousel: [],
        html: ''
      }
    }; 

    console.log(this.state.blogPosts);    
  }

  updateSlideDetails(event, slide) {    
    event.preventDefault();      
    this.setState({ 
      isLoading: true,
      folioSlide: { 
        isOpen: true,
        title: slide.childMarkdownRemark.frontmatter.title,
        description: slide.childMarkdownRemark.frontmatter.title,
        imgURL: slide.childMarkdownRemark.frontmatter.featured_image,
        html: slide.childMarkdownRemark.html,
        carousel: [...slide.childMarkdownRemark.frontmatter.carousel]
      }       
    });         
  }

  afterSlideOpened() {
    console.log("Opening....");
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  componentDidMount() {        
    Modal.setAppElement(this.el);  
  }

  renderFolioCarousel() {
    if(_.isUndefined(this.state.folioSlide.carousel)) 
      return false;
    
    if(this.state.folioSlide.carousel.length >= 1) {
      return(
        <Carousel slide={true}>
          {this.state.folioSlide.carousel.map(item => (            
            <Carousel.Item key={item.id} className="">
              <img src={item.img} alt="" className="rounded mx-auto d-block" />
            </Carousel.Item>
          ))}
        </Carousel>
      );
    } 
  }

  renderBlogPost(node) {
    const blogPath = "/articles" // TODO: Grab from config
    const { childMarkdownRemark } = node;
    const { frontmatter } = childMarkdownRemark;  
    const { title, slug, date, thumb_image } = frontmatter;
    const path = `${blogPath}/${slug}`;
    return(   
      <> 
        <Link to={path}>
          <div className="thumbnail">
            <img src={thumb_image} className="img-fluid" width="100%" alt={title} />
          </div>
          <h5 className="card-title" >{title}</h5>
        </Link>        
      </>
    )
  }

  render() {
    return(
      <Layout>
        <SEO title={this.state.page.frontmatter.title} />
        
        <IntroBlock 
          className="pt-4 pb-5" bg="dark" variant="dark" 
          heading={this.state.page.frontmatter.intro_block.heading}
          imgsrc={this.state.page.frontmatter.intro_block.image} 
          content={this.state.page.frontmatter.intro_block.content}      
        />              

        <div className="bg-darker pt-5 pb-6">

          <Container ref={ref => this.el = ref} className="px-6">
            
            <h2 className="mb-5 text-white">Showcase</h2>

            <Row className="folio-list no-gutters">
              {this.state.folioItems.map((item, index) => (
                <Col xs={12} md={4}>
                  <div key={`folio-${index}`} 
                    id={`#folio-${index}`} 
                    className="folio-list__item">
            
                    <a href={`#folio-${index}`} 
                      onClick={(e) => this.updateSlideDetails(e,item)}>
                      
                      <img 
                        width="480"
                        alt={item.childMarkdownRemark.frontmatter.title} 
                        src={item.childMarkdownRemark.frontmatter.featured_image}
                      />
            
                      <div className="folio-list__meta">
                        <span>{item.childMarkdownRemark.frontmatter.title}</span>
                      </div>
            
                    </a> 
                  </div>  
                </Col>
              ))}
            </Row>  
          </Container>
        </div>

        <ContentContainer heading="Latest Posts" px="5" className="latest-posts">         
          <div className="row blog-posts mb-4">
            {this.state.blogPosts.map((item, index) => (                             
              <div key={`blog-post-${index}`} className="col-md-3 blog-posts__item">
                { this.renderBlogPost(item) }                 
              </div>
            ))}                     
          </div>
        </ContentContainer>   
 
        <SlidingPane
            className="w-sm-100 w-md-50 slide-pane-folio-item folio-item"
            overlayClassName=''
            isOpen={ this.state.folioSlide.isOpen }
            title=""
            subtitle=""
            width='auto'   
            from="right" 
            onAfterOpen={ () => {
              this.afterSlideOpened();
            }}        
            onRequestClose={ () => {
              this.setState({ folioSlide: { isOpen: false } });
            }}>            

            <h2>{this.state.folioSlide.title}</h2>
            <p className="lead">{this.state.folioSlide.subtitle}</p>
            {this.renderFolioCarousel()}            

            <div className={`mt-3 folio-item__detail folio-item__detail-loading-${this.state.isLoading}`}>
              {ReactHtmlParser(this.state.folioSlide.html)}
            </div>                  
        </SlidingPane>    

      </Layout>
    );
  }
}    

export const query = graphql`
  { 
    page: file(name: { eq: "index" }, sourceInstanceName: {eq: "pages"}) {
      id
      childMarkdownRemark {
        html
        frontmatter {
          path
          title
          intro_block {
            heading
            image
            content
          }
        }
      }
    }   
    folio: allFile(sort: {fields: name}, filter: {sourceInstanceName: {eq: "folio"}, 
      childMarkdownRemark: {frontmatter: {onhome: {eq: true}}}}, limit: 9) {
      nodes {
        childMarkdownRemark {
          html
          frontmatter {
            title
            path
            group
            featured_image
            carousel {
              id
              img
            }
          }
        }
      }
    }
    blog: allFile(sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, filter: {sourceInstanceName: {eq: "blog"}, childMarkdownRemark: {}}, limit: 4) {
      nodes {
        childMarkdownRemark {        
          frontmatter {
            title
            slug
            date
            thumb_image            
          }
          html
        }
      }
    }
  }
`

export default IndexPage;



/* Folio List OLD
<Container ref={ref => this.el = ref} fluid={true} className="mx-0 px-0">
         
  <div className="folio-list">
    {this.state.folioItems.map((item, index) => (
                    
      <div key={`folio-${index}`} 
        id={`#folio-${item.childMarkdownRemark.id}`} 
        className="folio-list__item">

        <a href={`#folio-${index}`} 
          onClick={(e) => this.updateSlideDetails(e,item)}>
          
          <img 
            width="480"
            alt={item.childMarkdownRemark.frontmatter.title} 
            src={item.childMarkdownRemark.frontmatter.featured_image}
          />

          <div className="folio-list__meta">
            <span>{item.childMarkdownRemark.frontmatter.title}</span>
          </div>

        </a> 
      </div>             
    ))}                     
  </div> 
</Container>
*/
