import React from "react";
import cx from 'classnames';
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

function IntroBlock(props) {
  const { variant, bg, className, heading, content, imgsrc } = props
  const classes = cx(
    'intro-block',
    `intro-block-${variant || 'light'}`,
    `bg-${bg || 'light'}`, 
    className
  )

  return (
    <div className={classes}>
      <Container>
        <Row>
          <Col md={4} className="d-none d-md-block px-5">
            <Image src={imgsrc} roundedCircle fluid />
          </Col>
          <Col xs={12} md={7}>
            <div className="intro-block-copy text-light pt-3">
              <h1>{heading}</h1>
              {ReactHtmlParser(content)}
            </div>
          </Col>
        </Row>  
      </Container>
    </div>
  )
}

export default IntroBlock;